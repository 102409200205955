<template>
    <div class="home">
        <div class="pc-container d-none d-sm-block">
            <About></About>

            <div id="value" style="padding: 80px 120px 40px;background: #FFF9F7;">
                <div class="text-h4 primary-text text-center font-weight-bold mb-15">{{ $t('AppBar.SubMenu.value') }}</div>
                
                <v-row  class="d-flex justify-space-between my-10 fill-height" style="min-height:200px">
                    <v-col md="3" >
                        <v-card elevation="0" height="100%" color="#AC8250" class="pa-5 mr-2">
                            <div class="text-h6 text-center font-weight-bold white--text">{{ $t('Page.Fnd.Vision') }}</div>
                            <div class="white--text mt-6">{{ $t('Page.Fnd.VisionDesc') }}</div>
                        </v-card>
                    </v-col>
                    <v-col md="3">
                        <v-card elevation="0" height="100%" color="#AC8250" class="pa-5 mr-2">
                            <div class="text-h6 text-center font-weight-bold white--text">{{ $t('Page.Fnd.Mission') }}</div>
                            <div class="white--text mt-6">{{ $t('Page.Fnd.MissionDesc') }}</div>
                        </v-card>
                    </v-col>
                    <v-col md="3">
                        <v-card elevation="0" height="100%" color="#AC8250" class="pa-5 mr-2">
                            <div class="text-h6  text-center font-weight-bold white--text">{{ $t('Page.Fnd.Goal') }}</div>
                            <div class="white--text mt-6">{{ $t('Page.Fnd.GoalDesc') }}</div>
                        </v-card>
                    </v-col>
                    <v-col md="3">
                        <v-card elevation="0" height="100%" color="#AC8250" class="pa-5">
                            <div class="text-h6  text-center font-weight-bold white--text">{{ $t('Page.Fnd.Value') }}</div>
                            <div class="white--text mt-6">{{ $t('Page.Fnd.ValueDesc') }}</div>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
            <div id="safety" style="padding: 40px 120px;background: #F0F6F8;">
                <div class="d-flex justify-space-between align-top mx-auto" style="max-width:1800px;">
                    <div style="width:50%;padding: 40px 90px 40px 0;">
                        <div class="text-h4 primary-text font-weight-bold">{{ $t('Page.Govern.SubTitle3') }}</div>
                        <div class="mt-6" v-html="$t('Page.Govern.SubTitle3Desc')" style="line-height:26px"></div>
                    </div>
                    <div style="width:50%;padding: 40px 0 40px 90px;">
                        <div class="text-h4 primary-text font-weight-bold">{{ $t('Page.Govern.SutTitle4') }}</div>
                        <div class="mt-6" style="line-height:26px" v-html="$t('Page.Govern.SubTitle4Desc')"></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- mobile -->
        <div class="mobile-container d-block d-sm-none">
            <About></About>
            <div id="value-m" class="text-h4 primary-text text-center font-weight-bold my-15">{{ $t('AppBar.SubMenu.value') }}</div>
            
            <div class="block-a pt-15 pb-5 px-6" style="background:#F8F4F0;">
                <div class="text-h6 text-center">{{ $t('Page.Fnd.Vision') }}</div>
                <div class="mt-6">
                    {{ $t('Page.Fnd.VisionDesc') }}
                </div>

                <!-- <div class="text-h6 text-center pt-4 mt-5">{{ $t('Page.Fnd.Mission') }}</div>
                <div class="text-body-2 text-center my-4">
                    {{ $t('Page.Fnd.MissionDesc') }}
                </div> -->

                <!-- <v-img width="100%" src="@/assets/images/foundation_a.png" class="d-block"></v-img> -->
            </div>

            <div class="block-c pt-10 pb-5 px-6" style="background:rgb(223,214,215);">
                <div class="text-h6 text-center pt-4 mt-5">{{ $t('Page.Fnd.Mission') }}</div>
                <div class="text-body-2 text-center my-4">
                    {{ $t('Page.Fnd.MissionDesc') }}
                </div>
                <!-- <v-img width="100%" src="@/assets/images/foundation_c.png" class="d-block"></v-img> -->
            </div>
    
            <div class="block-b pt-0 pb-5 px-6" style="background:#F3F8F0;">
                <div class="text-h6 text-center pt-4">{{ $t('Page.Fnd.Goal') }}</div>
                <div class="text-body-2 text-center mt-2 mb-4">{{ $t('Page.Fnd.GoalDesc') }}</div>
                <!-- <v-img width="100%" src="@/assets/images/foundation_b.png" class="d-block"></v-img> -->
            </div>

            <div class="block-c pt-10 pb-5 px-6" style="background:#F0F5F8;">
                <div class="text-h6 text-center pt-4">{{ $t('Page.Fnd.Value') }}</div>
                <div class="text-body-2 text-center mt-2 mb-4">{{ $t('Page.Fnd.ValueDesc') }}</div>
                <!-- <v-img width="100%" src="@/assets/images/foundation_c.png" class="d-block"></v-img> -->
            </div>

            <div class="d-block d-sm-none" id="safety2">
                <div class="px-6 py-8">
                    <div class="text-h6 text-center pt-4">{{ $t('Page.Govern.SubTitle3') }}</div>
                    <div class="text-body-2 mt-2 mb-4" v-html="$t('Page.Govern.SubTitle3Desc')"></div>
                </div>
                <div class="text-center px-6 py-8">
                    <div class="text-h6 pt-4">{{ $t('Page.Govern.SutTitle4') }}</div>
                    <div class="text-body-2 mt-10" v-html="$t('Page.Govern.SubTitle4Desc')"></div>
                </div>
            </div>
            
        </div>
        <!-- <div id="govern1" class="d-block d-md-none" style="padding: 40px 20px 20px;">
                <div class="text-h5 primary-text text-center font-weight-bold mb-8">{{ $t('Page.Govern.Title') }}</div>
                <v-row class="my-8 px-6" >
                    <v-col md="6" class="pa-0">
                        <div class="pa-3 pl-4 primary-text fill-height d-flex flex-column align-left justify-start" style="background:#FFF9F7">
                            <div class="mb-1  text-h6 font-weight-bold">{{ $t('Page.Govern.SubTitle1') }}</div>
                            <div class="text-body-1">
                                <p>● {{ $t('Page.Govern.node1') }}</p>
                                <p>● {{ $t('Page.Govern.node2') }}</p>
                                <p>● {{ $t('Page.Govern.node3') }}</p>
                            </div>
                        </div>
                    </v-col>
                    <v-col md="6" class="pa-0">
                        <div class="pa-3 primary-text fill-height d-flex flex-column align-left justify-start" style="background:#FFF9F7">
                            <div class="mb-3 text-h6 font-weight-bold">{{ $t('Page.Govern.SubTitle2') }}</div>
                            <div class="text-body-1">
                                <p>{{ $t('Page.Govern.SutTitle2Desc') }}</p>
                            </div>
                        </div>
                    </v-col>
                </v-row>
        </div> -->

        <div class="partner-container" id="team">
            <div class="text-h4 primary-text text-center font-weight-bold mb-15">{{ $t('Page.Fnd.Team') }}</div>
            <Team></Team>
        </div>
        <Footer></Footer>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Footer from '../../components/Footer.vue'
  import About from '../about/About.vue'
  import Team from '../about/Team.vue'

  
  export default {
    name: 'Home',
    components: {
      Footer,
      About,
      Team
    },
    mounted(){
        //check anchor
        var hash = window.location.hash;
        if (hash) {
            var _this = this;
            setTimeout(() => {
                _this.goAnchor(hash.replace('#', ''));
            }, 200);
        }
    },
    watch: {
        $route(to){
            var hash = to.hash;
            if (hash) {
                var _this = this;
                setTimeout(() => {
                    _this.goAnchor(hash.replace('#', ''));
                }, 200);
            }
        }
    },

    methods: {
        goAnchor(id) {
            var anchor = document.getElementById(id);
            console.log(anchor);
            // chrome
            document.body.scrollTop = anchor.offsetTop;
            // firefox
            document.documentElement.scrollTop = anchor.offsetTop;
            // safari
            window.pageYOffset = anchor.offsetTop;
        }
    }
  }
  </script>
  
  <style lang="scss">
    .pc-container {
      min-width: 768px;
    }

    .subtit {
      position: relative;
    }

    .subtit::before {
      content: '';
      position: absolute;
      width: 150px;
      height: 7px;
      background: #ac8250;
      bottom: -20px;
      left: 0;
    }

    .partner-container {
        padding: 40px 120px;

        .team-title {
            margin-bottom: 80px;
        }
    }

    .fill-height {
        height: 100%!important;
    }
  
    @media screen and (max-width: 960px) {
        .mobile-container {
            overflow: hidden;
        }

        .banner {
            .text-title {
                font-size: 38px;
            }
        }

        .partner-container {
            padding: 30px 24px;
            
            .team-title {
                margin-bottom: 40px;
            }
        }

        .twitter, .medium, .discord {
            width: 30px!important;
        }
    }
  </style>
  